import { Grid, List, ListItem, styled, Typography } from "@mui/material";
import LogoComponent from "../../../../new_assets/LogoComponent";
import { Link } from "react-router-dom";
import { StyledContainer } from "../../../../new_components/layouts/MainLayout";
import CuracaoLogo from "../../../../new_assets/CuracaoLogo";

const FooterContainer = styled("footer")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: theme.spacing(8),
  "@media(max-width: 1279px)": {
    padding: theme.spacing(1.5),
  },
  "@media(max-width: 871px)": {
    flexDirection: "column",
  },
}));

const StyledList = styled(List)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(5),
  flexWrap: "wrap",
  "@media(max-width: 1039px)": {
    gap: theme.spacing(2),
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  ...theme.typography.subtitle2,
  width: "max-content",
  padding: 0,
  color: theme.palette.text.primary,
  "&:visited": {
    color: theme.palette.text.primary,
  },
}));

const HorizontalLine = styled("hr")(({ theme }) => ({
  width: "100%",
  borderTop: "none",
  borderBottomColor: theme.palette.surface.border.main,
  margin: 0,
}));

const CopyrightText = styled(Typography)(({ theme }) => ({
  maxWidth: theme.spacing(35.25),
}));

const Footer = () => {
  return (
    <FooterContainer>
      <Grid item>
        <StyledContainer container justifyContent="space-between" pb={4}>
          <Grid item>
            <LogoComponent />
          </Grid>
          <Grid item>
            <StyledList>
              <StyledListItem color="inherit" component={Link} to="/#features">Features</StyledListItem>
              <StyledListItem color="inherit" component={Link} to="/#benefits">Benefits</StyledListItem>
              <StyledListItem color="inherit" component={Link} to="/#our-brand">Our Brand</StyledListItem>
              <StyledListItem color="inherit" component={Link} to="/#about">About</StyledListItem>
              <StyledListItem color="inherit" component={Link} to="/#contact">Contacts</StyledListItem>
              <StyledListItem color="inherit" component={Link} to="/faqs">FAQ`s</StyledListItem>
              <StyledListItem color="inherit" component={Link} to="/privacy-policy">Privacy Policy</StyledListItem>
              <StyledListItem color="inherit" component={Link} to="/terms">Terms&Conditions</StyledListItem>
            </StyledList>
          </Grid>
        </StyledContainer>
      </Grid>
      <HorizontalLine />
      <Grid item pt={4} mb={9}>
        <StyledContainer container justifyContent="space-between">
          <CopyrightText variant="body2">London, United Kingdom © Copyright 2024 Vegas Affiliate, All Rights
            Reserved</CopyrightText>
          <CuracaoLogo sx={{ fontSize: 122, height: "auto" }} />
        </StyledContainer>
      </Grid>
    </FooterContainer>
  );
};

export default Footer;