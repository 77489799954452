import { Grid, Link, styled, Typography } from "@mui/material";
import UnderlinedHeaderTextWord from "../../../../new_components/underlined-header-text-word";
import OrangeArrowButton from "../../../../new_components/buttons/orange-arrow-button";
import StyledTextField from "../../../../new_components/styled-text-field";

const GetInTouchContainer = styled(Grid)(({ theme }) => ({
  backgroundImage: "linear-gradient(to bottom, #F7F4EF 25%, transparent 45%, transparent 70%, #F7F4EF 80%), " +
    "linear-gradient(to right, #edebe9 1px, transparent 1px), " +
    "linear-gradient(to bottom, #edebe9 1px, transparent 1px)",
  backgroundSize: "100% 100%, 105px 105px, 105px 105px",
  marginTop: theme.spacing(15.5),
  [theme.breakpoints.down("lg")]: {
    marginTop: theme.spacing(10),
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  "@media(max-width: 1221px)": {
    // justifyContent: "center",
  },
}));

const HeadingContainer = styled(Grid)(({ theme }) => ({
  width: theme.spacing(92.75),
  // height: theme.spacing(91.25),
  height: "auto",
  aspectRatio: 1.016,
  position: "relative",
  "& > div[class*='triangle']": {
    position: "absolute",
    width: 0,
    height: 0,
    zIndex: 2,
  },
  ".top-triangle": {
    top: 0,
    left: 0,
    right: 0,
    borderLeft: "150px solid transparent",
    borderRight: "525px solid transparent",
    borderTop: `200px solid ${theme.palette.background.medium}`,
    filter: "drop-shadow(0px 10px 7px rgba(0, 0, 0, 0.03))",
    transform: "translate(18%, 29%) rotate(345deg)",

  },
  ".right-triangle": {
    transform: "translate(0, 53%)",
    top: 0,
    bottom: 0,
    left: 0,
    borderTop: "150px solid transparent",
    borderBottom: "230px solid transparent",
    borderLeft: `280px solid ${theme.palette.background.medium}`,
    filter: "drop-shadow(2px 4px 3px rgba(0, 0, 0, 0.03))",
  },
  ".bottom-triangle": {
    bottom: 0,
    left: 0,
    right: 0,
    borderLeft: "358px solid transparent",
    borderRight: "450px solid transparent",
    borderBottom: `303px solid ${theme.palette.background.medium}`,
    filter: "drop-shadow(-3px -1px 3px rgba(0, 0, 0, 0.03))",
  },
  "@media(max-width: 1221px)": {
    zoom: 0.8,
  },
  "@media(max-width: 705px)": {
    zoom: 0.4,
  },
}));

const FormSideContainer = styled(Grid)(({ theme }) => ({
  maxWidth: theme.spacing(60),
}));

const FormContainer = styled(Grid)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1.5),
  marginTop: theme.spacing(3),
}));

const GetInTouch = () => {
  return (
    <GetInTouchContainer container mt={15.5} justifyContent="space-between" alignItems="center">
      <Grid item>
        <HeadingContainer container justifyContent="center" alignItems="center" height="100%">
          <div className="top-triangle"></div>
          <div className="right-triangle"></div>
          <div className="bottom-triangle"></div>
          <Typography variant="getInTouchHeading">Get
            in <br /><UnderlinedHeaderTextWord>touch</UnderlinedHeaderTextWord></Typography>
        </HeadingContainer>
      </Grid>
      <Grid item>
        <FormSideContainer container>
          <Typography variant="subtitle2">You can reach our dedicated affiliate team via our live chat system or through
            the contact form below. Alternatively, please email{" "}
            <Link href="mailto:affiliates@vegasaffiliate.com" target="_blank"
                  rel="noopener noreferrer">affiliates@vegasaffiliate.com</Link>
          </Typography>
          <FormContainer>
            <div>
              <StyledTextField label="Company" variant="filled" fullWidth />
            </div>
            <div>
              <StyledTextField label="Name" variant="filled" fullWidth />
            </div>
            <div>
              <StyledTextField label="Email" variant="filled" type="email" fullWidth />
            </div>
            <div>
              <StyledTextField
                label="Message"
                multiline
                rows={4}
                variant="filled"
                fullWidth
              />
            </div>
            <OrangeArrowButton sx={{ alignSelf: "flex-end" }}>Send message</OrangeArrowButton>
          </FormContainer>
        </FormSideContainer>
      </Grid>
    </GetInTouchContainer>
  );
};

export default GetInTouch;